import React from 'react';

import DefaultTemplate from 'src/templates/default';
import HomepageSocialProof from 'src/components/homepage-social-proof';
import PageHeader from 'src/components/headers/page-header';
import Showcase from 'src/components/showcase';
import FeaturesSection from 'src/components/features/feature-section-usecases';
import { Faq } from 'src/components/Surfaces';
import { RelatedArticles } from 'src/components/RelatedArticles';

import { Locales } from 'src/lib/constants';
import { mutateHardCodedLinks } from 'src/lib/utils';

import * as enGbHomepage from 'src/store/en-gb/homepage';

const homepageData = mutateHardCodedLinks({
  data: enGbHomepage,
  locale: Locales.EN_GB,
});

const UKLandingPage = ({ locale = Locales.EN_GB }) => {
  // data
  const {
    metaData,
    pageHeaderData,
    socialProofData,
    valuesData,
    featuresData,
    relatedArticlesData,
    accordionData,
  } = homepageData;
  return (
    <DefaultTemplate {...metaData} locale={locale}>
      <PageHeader {...pageHeaderData} />
      <HomepageSocialProof {...socialProofData} />
      {valuesData.map((value, i) => {
        return <Showcase {...value} key={`value-${i}`} />;
      })}
      <FeaturesSection {...featuresData} />
      <RelatedArticles {...relatedArticlesData} />
      <Faq items={accordionData} />
    </DefaultTemplate>
  );
};

export default UKLandingPage;
